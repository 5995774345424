import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
    Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    cancelAppointment,
    createCall,
    getRequestAppointment,
    getScheduledAppointment,
    verifyRequestAppointment,
} from '../../../api/adapters/appointment';
import { updateMultipleNotification } from '../../../api/adapters/notification';
import DS from '../../../assets/images/ds-ic.svg';
import AS from '../../../assets/images/sort-icon.svg';
import { customTotal } from '../../../configs/common.config';
import {
    deleteAppointmentNotification,
    deleteScheduledNotification,
} from '../../../redux/actions/notification';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import {
    getExpandedDateString,
    getFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';
import DropDownSelector from '../../components/mini-components/DropDownSelector';
import RowAction from '../../components/mini-components/RowAction';
import NoAppointment from './NoAppointment';
export const Dialoguers = (props) => {
    const { oName, city, country, profilePic, isReschedule, activeKey, id } =
        props;

    return (
        <div className='dialoguers'>
            <Link
                to={
                    isReschedule
                        ? `/appointments/${id}/reschedule-request`
                        : activeKey === 'schedule'
                        ? `/appointments/schedule/${id}/view`
                        : activeKey === 'request'
                        ? `/appointments/request/${id}/view`
                        : ''
                }
            >
                <div className='details'>
                    <div className='dp'>
                        <img
                            src={
                                profilePic ||
                                '/images/profile-image-placeholder.svg'
                            }
                            alt=''
                        />
                    </div>
                    <div>
                        <p>{getFullName(oName)}</p>
                        <p className='small'>
                            <img src='/images/table-ic-location.svg' alt='' />
                            {city}, {country}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
};
function Appointments() {
    const pageSize = 5;
    const { SearchBar } = Search;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const notification = useSelector((state) => state.notification);

    const [formErrors, setFormErrors] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [cancelId, setCancelId] = useState(null);
    const [name, setName] = useState('');
    // const [ratingPopup, setRatingPopup] = useState(false);
    // const [rating, setRating] = useState(5);

    const [selectedSlot, setSelectedSlot] = useState(null);
    const [activeKey, setActiveKey] = useState(params?.status);

    const [totalScheduleRecords, setTotalScheduleRecords] = useState(0);
    const [totalRequestRecords, setTotalRequestRecords] = useState(0);
    const [searchText, setSearchText] = useState('');

    // const [currentPage, setCurrentPage] = useState(1);
    const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);
    const [requestCurrentPage, setRequestCurrentPage] = useState(1);
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'desc', column: 0 }],
        columns: [{ data: 'firstSlot.dStartTime' }],
        search: '',
    });

    const [scheduleQueryData, setScheduleQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'oAvailability.aSlots.dStartTime' }],
        search: '',
    });

    const {
        data: requestAppointments,
        refetch: refetchRequestAppointments,
        isLoading: requestDataLoading,
    } = useQuery({
        queryKey: ['request-appointments', queryData, activeKey],
        queryFn: () =>
            getRequestAppointment(i18n.language.split('-')[0], queryData).then(
                (res) => res.data
            ),
        enabled: false,
    });

    const {
        data: scheduledAppointments,
        isLoading: scheduledDataLoading,
        refetch: refetchScheduledAppointments,
    } = useQuery({
        queryKey: ['schedule-appointments', scheduleQueryData, activeKey],
        queryFn: () =>
            getScheduledAppointment(
                i18n.language.split('-')[0],
                scheduleQueryData
            ).then((res) => res.data),
        enabled: false,
    });

    useEffect(() => {
        if (activeKey === 'schedule' && !scheduledDataLoading) {
            setTotalScheduleRecords(scheduledAppointments?.recordsTotal);
        }
    }, [scheduledDataLoading, scheduledAppointments]);

    useEffect(() => {
        setTotalRequestRecords(requestAppointments?.recordsTotal);
    }, [requestDataLoading, requestAppointments]);

    useEffect(() => {
        if (activeKey === 'request') refetchRequestAppointments();
    }, [queryData, activeKey]);

    useEffect(() => {
        if (params?.status) {
            setActiveKey(params?.status);
        }
    }, [params.status]);

    useEffect(() => {
        document.title = formatPageTitle(t('appointments.appointments'));

        const newCol = col_two.map((item) => ({
            ...item,
            text: t(item.text),
        }));
        setRequestColumns(newCol);
    }, [i18n.language]);

    useEffect(() => {
        if (activeKey === 'schedule') refetchScheduledAppointments();
    }, [scheduleQueryData, activeKey]);

    useEffect(() => {
        const newCol = col_one.map((item) => ({
            ...item,
            text: t(item.text),
        }));
        setscheduledColumns(newCol);
    }, [i18n.language]);

    const updateNotification = useCallback((type) => {
        mutateUpdateNotification(type);
    }, []);

    const { mutate: mutateUpdateNotification } = useMutation({
        mutationFn: (type) =>
            updateMultipleNotification(i18n.language.split('-')[0], type),
        onSuccess: (response, data) => {},
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const aAppointmentScheduled = notification.filter((item) =>
        ['ACCEPTED'].includes(item.sNotificationType)
    );
    const aAppointmentRequest = notification.filter((item) =>
        ['APPOINTMENT', 'REJECTED', 'CANCELED', 'RESCHEDULED'].includes(
            item.sNotificationType
        )
    );
    useEffect(() => {
        if (params?.status === 'schedule') {
            if (aAppointmentScheduled.length > 0) {
                updateNotification({ type: 'SCHEDULED' });
                dispatch(deleteScheduledNotification());
            }
        }
        if (params?.status === 'request') {
            if (aAppointmentRequest.length > 0) {
                updateNotification({ type: 'REQUEST' });
                dispatch(deleteAppointmentNotification());
            }
        }
    }, [updateNotification, params?.status]);

    const customTotalWithTranslation = (from, to, size) => {
        return customTotal(from, to, size, t); // Pass `t` here
    };

    const options = {
        remote: { pagination: true },
        pageStartIndex: 1,
        hideSizePerPage: true,
        page: scheduleCurrentPage,
        hidePageListOnlyOnePage: true,
        sizePerPage: pageSize,
        totalSize: totalScheduleRecords,
        showTotal: true,
        prePageText: <img src='/images/icons/chevron-prev.svg' alt='' />,
        nextPageText: <img src='/images/icons/chevron.svg' alt='' />,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        onPageChange: onScheduleTableChange,
        paginationTotalRenderer: customTotalWithTranslation,
    };

    const requestOptions = {
        remote: { pagination: true },
        pageStartIndex: 1,
        hideSizePerPage: true,
        page: requestCurrentPage,
        hidePageListOnlyOnePage: true,
        sizePerPage: pageSize,
        totalSize: totalRequestRecords,
        showTotal: true,
        prePageText: <img src='/images/icons/chevron-prev.svg' alt='' />,
        nextPageText: <img src='/images/icons/chevron.svg' alt='' />,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        onPageChange: onRequestTableChange,
        paginationTotalRenderer: customTotalWithTranslation,
    };

    const HandleTableChange = (type, { sortField, sortOrder, page, data }) => {
        if (sortField === 'date') {
            setQueryData({
                ...queryData,
                order: [
                    {
                        dir: sortOrder,
                        column: col_two.findIndex(
                            (col) => col.dataField === sortField
                        ),
                    },
                ],
            });
        }
        if (type === 'pagination') {
            onRequestTableChange(page);
        }
    };
    const HandleScheduleTableChange = (
        type,
        { sortField, sortOrder, page, data }
    ) => {
        if (sortField === 'date') {
            setScheduleQueryData({
                ...scheduleQueryData,
                order: [
                    {
                        dir: sortOrder,
                        column: col_one.findIndex(
                            (col) => col.dataField === sortField
                        ),
                    },
                ],
            });
        }
        if (type === 'pagination') {
            onScheduleTableChange(page);
        }
    };

    const { mutate: mutateVerifyRequestAppointment } = useMutation({
        mutationFn: (data) =>
            verifyRequestAppointment(i18n.language.split('-')[0], data),
        onSuccess: (response, queryData) => {
            notify('success', response.message);

            if (queryData?.eStatus === 'ACCEPTED') {
                setModalShow(true);
                refetchRequestAppointments();
                refetchScheduledAppointments();
            } else {
                refetchRequestAppointments();
                refetchScheduledAppointments();
            }
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const { mutate: mutateCancelAppointment } = useMutation({
        mutationFn: ({ id }) =>
            cancelAppointment(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            setCancelModalShow(false);
            navigate('/appointments/request');
            refetchRequestAppointments();
            refetchScheduledAppointments();
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const { mutate: mutateCreateCall } = useMutation({
        mutationFn: (id) => createCall(i18n.language.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/call', { state: { oCallData: response.data } });
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    const handleSlot = (selectedOption) => {
        setSelectedSlot(selectedOption.value);
    };

    const col_one = [
        {
            dataField: 'date',
            text: 'table_header.date',
            headerClasses: 'filter-column',
            sort: true,
            sortCaret: (order, column) => {
                if (order === 'asc') {
                    return <img src={AS} alt='' />;
                } else if (order === 'desc') {
                    return <img src={DS} alt='' />;
                }
            },
        },
        {
            dataField: 'time_slot',
            text: 'table_header.time_slots',
        },
        {
            dataField: 'dialoguers',
            text: 'table_header.dialoguers',
            headerClasses: 'filter-column profile-detail-col',
            sort: true,
            sortCaret: (order, column) => {
                if (order === 'asc') {
                    return <img src={AS} alt='' />;
                } else if (order === 'desc') {
                    return <img src={DS} alt='' />;
                }
            },
        },
        {
            dataField: 'actions',
            text: 'table_header.actions',
        },
    ];

    const col_two = [
        {
            dataField: 'date',
            text: 'table_header.date',
            headerClasses: 'filter-column',
            sort: true,
            sortCaret: (order, column) => {
                if (order === 'asc') {
                    return <img src={AS} alt='' />;
                } else if (order === 'desc') {
                    return <img src={DS} alt='' />;
                }
            },
        },
        {
            dataField: 'time_slot',
            text: 'table_header.time_slots',
        },
        {
            dataField: 'dialoguers',
            text: 'Dialoguers',
            headerClasses: 'filter-column profile-detail-col',
            sort: true,
            sortCaret: (order, column) => {
                if (order === 'asc') {
                    return <img src={AS} alt='' />;
                } else if (order === 'desc') {
                    return <img src={DS} alt='' />;
                }
            },
        },
        {
            dataField: 'actions',
            text: 'table_header.actions',
        },
    ];

    const handleCancelClick = (id) => {
        setCancelId(id);
        setCancelModalShow(true);
    };

    const handleClick = () => {
        mutateCancelAppointment({
            id: cancelId,
        });
    };

    const handleCallClick = async (id) => {
        mutateCreateCall(id);
    };

    const handleReschedule = (id) => {
        navigate(`/appointments/${id}/reschedule`);
    };

    const scheduled = (scheduledAppointments?.data || [])?.map((item, i) => {
        return {
            key: i,
            date: getExpandedDateString(
                moment(item?.oAvailability?.aSlots?.dStartTime)
                    .startOf('day')
                    .format(),
                i18n.language.split('-')[0]
            ),
            time_slot:
                moment(item?.oAvailability?.aSlots?.dStartTime).format('H:mm') +
                ' - ' +
                moment(item?.oAvailability?.aSlots?.dEndTime).format('H:mm'),
            dialoguers: (
                <Dialoguers
                    oName={item?.oUser?.oName}
                    city={item?.oUser?.oCity}
                    country={item?.oUser?.oCountry}
                    profilePic={item?.oUser?.sProfilePicUrl}
                    userId={item?.oUser?._id}
                    isReschedule={item?.nRescheduleCount}
                    activeKey={activeKey}
                    id={item?._id}
                />
            ),
            actions: (
                <RowAction
                    activeKey={activeKey}
                    userId={item?.oUser?._id}
                    id={item?._id}
                    status={item?.eStatus}
                    isReschedule={item?.nRescheduleCount}
                    actionBtn={[
                        {
                            btnTitle: t('chat_btn'),
                            btnType: 'chat',
                            handleOnClick: () => {
                                navigate('/chat', {
                                    state: {
                                        conversationId: item?.iConversationId,
                                    },
                                });
                            },
                        },
                        {
                            btnTitle: t('call_btn'),
                            btnType: 'call',
                            disabled:
                                moment(item?.oAvailability?.aSlots?.dStartTime)
                                    .subtract(2, 'minutes')
                                    .isAfter(moment()) ||
                                moment(
                                    item?.oAvailability?.aSlots?.dEndTime
                                ).isBefore(moment()),
                            handleOnClick: () => {
                                handleCallClick(item?._id);
                            },
                        },
                        {
                            btnTitle: t('appointments.reschedule'),
                            btnType: 'reschedule',
                            disabled:
                                item?.nRescheduleCount >= 2 ||
                                moment(
                                    item?.oAvailability?.aSlots?.dStartTime
                                ).isBefore(moment()),
                            handleOnClick: () => handleReschedule(item._id),
                        },
                        {
                            btnTitle: t('cancel'),
                            btnType: 'cancel',
                            disabled: moment(
                                item?.oAvailability?.aSlots?.dStartTime
                            )
                                .subtract(1, 'hour')
                                .isBefore(moment()),
                            handleOnClick: () => handleCancelClick(item?._id),
                        },
                    ]}
                />
            ),
        };
    });

    const timeSlots = (time) => {
        // Check if time is null or undefined
        if (!time) {
            return [];
        }
        // Sort the time array in ascending order
        const sortedTime = time.sort((a, b) => {
            const aStartTime = moment(a?.dStartTime);
            const bStartTime = moment(b?.dStartTime);

            if (aStartTime.isBefore(bStartTime)) {
                return -1;
            } else if (aStartTime.isAfter(bStartTime)) {
                return 1;
            } else {
                return 0;
            }
        });

        return sortedTime.map((item) => ({
            value: item._id,
            label:
                `${moment(item?.dStartTime).format('H:mm')}` +
                ' ' +
                '-' +
                ' ' +
                `${moment(item?.dEndTime).format('H:mm')}`,
        }));
    };

    const handleStartTime = (time) => {
        const minute = time.reduce((maxStartTime, time) => {
            return !maxStartTime || time.dStartTime > maxStartTime
                ? time.dStartTime
                : maxStartTime;
        }, null);

        const originalTime = moment(minute);

        const updateStartTime = originalTime.clone().subtract(2, 'hour');

        return updateStartTime;
    };

    const handleEndTime = (time) => {
        const minute = time.reduce((maxStartTime, time) => {
            return !maxStartTime || time.dStartTime > maxStartTime
                ? time.dStartTime
                : maxStartTime;
        }, null);

        const originalTime = moment(minute);

        const updateEndTime = originalTime.clone().subtract(1, 'hour');

        return updateEndTime;
    };

    const handleActionButton = ({ id, name, status, slot }) => {
        setName(name);
        mutateVerifyRequestAppointment({
            id: id,
            iSelectedSlot: selectedSlot || slot?.[0]?.value || null,
            eStatus: status === 'Accept' ? 'ACCEPTED' : 'REJECTED',
        });
    };

    const request = (requestAppointments?.data || [])?.map((item, i) => {
        const getScheduledDates = (slots) => {
            const aDates = slots.map((item) => {
                return moment(item.dStartTime).startOf('day').format();
            });
            const uniqueDates = [...new Set(aDates)];
            return uniqueDates;
        };
        const aDates = getScheduledDates(item?.matchedSlots);

        return {
            key: i,
            date:
                aDates?.length === 1
                    ? getExpandedDateString(
                          aDates[0],
                          i18n.language.split('-')[0]
                      )
                    : getExpandedDateString(
                          aDates[0],
                          i18n.language.split('-')[0]
                      ) +
                      ' - ' +
                      getExpandedDateString(
                          aDates[1],
                          i18n.language.split('-')[0]
                      ),
            time_slot: (
                <DropDownSelector
                    time={timeSlots(item?.matchedSlots)}
                    customClass='tableDropdown'
                    handleOnChange={handleSlot}
                    status={item?.eStatus}
                    // value={timeSlots(item?.matchedSlots)[0]}
                    defaultValue={timeSlots(item?.matchedSlots)[0]}
                />
            ),
            dialoguers: (
                <Dialoguers
                    oName={item?.oUser?.oName}
                    city={item?.oUser?.sCity}
                    country={item?.oUser?.sCountry}
                    profilePic={item?.oUser?.sProfilePicUrl}
                    userId={item?.oUser?._id}
                    activeKey={activeKey}
                    id={item?._id}
                />
            ),
            actions: (
                <RowAction
                    counterStartTime={handleStartTime(item?.matchedSlots)}
                    counterEndTime={handleEndTime(item?.matchedSlots)}
                    name={item?.oUser?.oName}
                    id={item?._id}
                    mutateVerifyRequestAppointment={
                        mutateVerifyRequestAppointment
                    }
                    refetchRequestAppointments={refetchRequestAppointments}
                    status={item?.eStatus}
                    selectedSlot={selectedSlot}
                    actionBtn={
                        item?.eStatus === 'REJECTED'
                            ? [
                                  {
                                      btnTitle: t('rejected'),
                                      btnType: 'cancel',
                                      disabled: true,
                                  },
                              ]
                            : item?.eStatus === 'CANCELED'
                            ? [
                                  {
                                      btnTitle: t('canceled'),
                                      btnType: 'cancel',
                                      disabled: true,
                                  },
                              ]
                            : (item?.iSenderId === user.id &&
                                  item?.eStatus === 'RESCHEDULE_REQUEST') ||
                              (item?.iSenderId === user.id &&
                                  item?.eStatus === 'PENDING')
                            ? [
                                  {
                                      btnTitle: t('pending'),
                                      btnType: 'chat',
                                      disabled: true,
                                  },
                              ]
                            : item?.iSenderId === user.id &&
                              item?.eStatus === 'REJECTED'
                            ? [
                                  {
                                      btnTitle: t('rejected'),
                                      btnType: 'cancel',
                                      disabled: true,
                                  },
                              ]
                            : item?.iSenderId === user.id &&
                              item?.eStatus === 'CANCELED'
                            ? [
                                  {
                                      btnTitle: t('canceled'),
                                      btnType: 'cancel',
                                      disabled: true,
                                  },
                              ]
                            : [
                                  {
                                      btnTitle: t('appointments.accept'),
                                      btnType: 'accept',
                                      handleOnClick: () => {
                                          handleActionButton({
                                              id: item?._id,
                                              name: item?.oUser?.oName,
                                              status: 'Accept',
                                              slot: timeSlots(
                                                  item?.matchedSlots
                                              ),
                                          });
                                      },
                                  },
                                  {
                                      btnTitle: t('appointments.reject'),
                                      btnType: 'cancel',
                                      handleOnClick: () => {
                                          handleActionButton({
                                              id: item?._id,
                                              name: item?.oUser?.oName,
                                              status: 'Reject',
                                          });
                                      },
                                  },
                              ]
                    }
                />
            ),
        };
    });

    const [scheduledColumns, setscheduledColumns] = useState(
        col_one.map((item) => ({
            ...item,
            text: t(item.text),
        }))
    );

    const [requestColumns, setRequestColumns] = useState(
        col_two.map((item) => ({
            ...item,
            text: t(item.text),
        }))
    );

    const handleRequestSearch = (e) => {
        let value = e.target.value;
        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
        setScheduleQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const handleNavItemClick = (eventKey) => {
        setActiveKey(eventKey);
        navigate(`/appointments/${eventKey}`);

        if (eventKey === 'schedule') {
            setScheduleCurrentPage(1);
            setScheduleQueryData((prevData) => {
                return {
                    ...prevData,
                    start: 0,
                    search: '',
                };
            });
        } else if (eventKey === 'request') {
            setRequestCurrentPage(1);
            setQueryData((prevData) => {
                return {
                    ...prevData,
                    start: 0,
                    search: '',
                };
            });
        }
    };

    const handleSearch = (searchText) => {
        setSearchText(searchText);
        setScheduleQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: searchText,
        }));
    };

    function onRequestTableChange(page) {
        setRequestCurrentPage(page);
        setQueryData((prev) => ({ ...prev, start: (page - 1) * pageSize }));
    }

    function onScheduleTableChange(page) {
        setScheduleCurrentPage(page);
        setScheduleQueryData((prev) => ({
            ...prev,
            start: (page - 1) * pageSize,
        }));
    }

    const defaultSortedBy = [
        {
            dataField: 'date',
            order: 'desc',
        },
    ];

    const defaultSortedBy2 = [
        {
            dataField: 'date',
            order: 'desc',
        },
    ];

    const aAllApointments = notification.filter((item) => {
        return (
            item.sNotificationType === 'APPOINTMENT' ||
            item.sNotificationType === 'RESCHEDULED' ||
            item.sNotificationType === 'REJECTED' ||
            item.sNotificationType === 'CANCELED'
        );
    });

    const aAllScheduledApointments = notification.filter((item) => {
        return item.sNotificationType === 'ACCEPTED';
    });

    return (
        <div className='appointments'>
            <BreadcrumbAction lastPage={t('appointments.appointments')} />
            <div className='custom-tabs'>
                <Tabs
                    defaultActiveKey='schedule'
                    activeKey={activeKey}
                    id='uncontrolled-tab-example'
                    className='tabs-wrapper'
                    onSelect={handleNavItemClick}
                >
                    <Tab
                        eventKey='schedule'
                        title={
                            <span className='tab-counter '>
                                {t('appointments.scheduled')}{' '}
                                {/* <span class='notify-count'>1</span> */}
                                {aAllScheduledApointments.length > 0 && (
                                    <span className='notify-count'>
                                        {aAllScheduledApointments.length}
                                    </span>
                                )}
                            </span>
                        }
                    >
                        <div className='table-search'>
                            <img src='/images/search-ic.svg' alt='' />

                            <SearchBar
                                // {...props.searchProps}
                                delay={300}
                                placeholder={t('appointments.search')}
                                onSearch={(value) => {
                                    handleSearch(value);
                                }}
                                searchText={searchText}
                                controlled
                                className='custom-searchbar'
                            />
                        </div>
                        {/* when data is not available for Scheduled  */}
                        {scheduledDataLoading ? (
                            <div className='content-wrapper h-100'>
                                <Loader innerLoader={true} />
                            </div>
                        ) : !scheduledAppointments?.data.length ? (
                            <NoAppointment desc={t('appointments.go_and')} />
                        ) : (
                            <div className='pagination-table'>
                                <ToolkitProvider
                                    keyField='id'
                                    data={scheduled}
                                    columns={scheduledColumns}
                                    search
                                >
                                    {(props) => {
                                        return (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    keyField='id'
                                                    defaultSorted={
                                                        defaultSortedBy2
                                                    }
                                                    className='my-custom-table'
                                                    pagination={paginationFactory(
                                                        options
                                                    )}
                                                    onTableChange={
                                                        HandleScheduleTableChange
                                                    }
                                                    remote={true}
                                                />
                                            </div>
                                        );
                                    }}
                                </ToolkitProvider>
                            </div>
                        )}
                    </Tab>
                    <Tab
                        eventKey='request'
                        title={
                            <span className='tab-counter '>
                                {t('appointments.request')}{' '}
                                {aAllApointments.length > 0 && (
                                    <span className='notify-count'>
                                        {aAllApointments.length}
                                    </span>
                                )}
                            </span>
                        }
                    >
                        <div className='table-search'>
                            <img src='/images/search-ic.svg' alt='' />

                            <input
                                type='text'
                                placeholder={t('appointments.search')}
                                className='custom-searchbar'
                                value={queryData?.search}
                                onChange={(e) => handleRequestSearch(e)}
                            />
                        </div>
                        {/* When data is not available for Request  */}

                        {requestDataLoading ? (
                            <div className='content-wrapper h-100'>
                                <Loader innerLoader={true} />
                            </div>
                        ) : !requestAppointments?.data.length ? (
                            <NoAppointment
                                desc={t('appointments.no_requests')}
                            />
                        ) : (
                            <div className='pagination-table'>
                                <ToolkitProvider
                                    keyField='id'
                                    data={request}
                                    columns={requestColumns}
                                    search
                                >
                                    {(props) => {
                                        return (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    keyField='id'
                                                    defaultSorted={
                                                        defaultSortedBy
                                                    }
                                                    className='my-custom-table'
                                                    pagination={paginationFactory(
                                                        requestOptions
                                                    )}
                                                    onTableChange={
                                                        HandleTableChange
                                                    }
                                                    remote={true}
                                                />
                                            </div>
                                        );
                                    }}
                                </ToolkitProvider>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>

            <ModalPopUp
                show={cancelModalShow}
                onHide={() => setCancelModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('appointments.cancel_appointment')}
                        </h3>

                        <p className='sm'>{t('appointments.are_you')}</p>
                        <div className='error-msg edit-profile-error justify-content-start'>
                            <img
                                style={{
                                    maxWidth: '16px',
                                    marginTop: '4px',
                                    lineHeight: '20px',
                                }}
                                src='/images/icons/error-ic.svg'
                                alt='error-ic'
                            />
                            <p
                                className='error small'
                                style={{ fontSize: '14px' }}
                            >
                                {t('appointments.if_you')}
                            </p>
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setCancelModalShow(false)}
                            >
                                {t('appointments.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => {
                                    handleClick();
                                }}
                            >
                                {t('appointments.yes_cancel')}
                            </button>
                        </div>
                    </>
                }
            />

            <ModalPopUp
                show={modalShow}
                isBackDrop={true}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <h3 className='popup-title'>
                            {t('appointment_confirmed')}
                        </h3>

                        <img src='/images/icons/popUp-logo.svg' alt='' />

                        <p className='sm'>
                            <b>
                                {' '}
                                {t('popup_content.your_appointment')}{' '}
                                {name?.sFirstName}{' '}
                                {name?.sMiddleName ? name?.sMiddleName : null}{' '}
                                {name?.sLastName}{' '}
                                {t('popup_content.is_confirmed')}
                            </b>
                            {t('popup_content.reschedule_cancel')}
                            <b>
                                {t('popup_content.health_of_Dialoguers')}
                            </b>{' '}
                            {t('popup_content.responsibility')}
                        </p>
                    </>
                }
            />
        </div>
    );
}

export default Appointments;
